import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import { i18n } from "./plugins/i18n.js";
import FlagIcon from "vue-flag-icon";
import "tiptap-vuetify/dist/main.css";
import "./registerServiceWorker";
import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";
import VueDragSelector from "vue-drag-selector";
import VueAnalytics from "vue-analytics";
import VueCookies from "vue-cookies";
import SequentialEntrance from "vue-sequential-entrance";
import "vue-sequential-entrance/vue-sequential-entrance.css";
import ScrollLoader from "vue-scroll-loader";
import colors from 'vuetify/lib/util/colors'
import VueGtag from 'vue-gtag';
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

Vue.$cookies.config('1d', '', '', true)

Vue.use(ScrollLoader);

const isPROD =
  process.env.NODE_ENV === "production" &&
  process.env.VUE_APP_ENVIRONMENT === "PRODUCTION"
    ? true
    : false;
const isDEBUG = process.env.NODE_ENV !== "production" ? true : false;

Vue.use(VueAnalytics, {
  id: "UA-154112742-2",
  router,
  debug: {
    enabled: false,
    trace: isDEBUG,
    sendHitTask: isPROD
  }
});

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_ANALYTICS
  }
});

Vue.use(VueCookies);

Vue.use(SequentialEntrance);

Vue.use(VueDragSelector);
Vue.use(VueAxios, axios);
Vue.use(FlagIcon);
Vue.use(Vuetify);
// localStorage.setItem("changeStoreUI", "true");
Vue.config.productionTip = false;
const vuetify = new Vuetify({
  icons: {
    iconfont: "md"
  },
  theme: {
    themes: {
      light: {
        background: colors.shades.white
      },
      dark: {
        background: colors.grey.darken4
      }
    }
  }
});

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify: vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "md"
});

Vue.mixin({
  data: function() {
    return {
      PROJECT_ENV: "DEV",
      SERVICE_ONEBOX: "https://etaxgateway.one.th/onebox/"
      // PROJECT_ENV: 'PROD',
      // API_ONEBOX: 'https://etaxgateway.one.th/onebox/',
    };
  }
});

Vue.config.productionTip = false;

Vue.use(new VueSocketIO({
  debug: false,
  connection: SocketIO('https://onebox.thaimediafund.or.th', {
    path: '/document_editor/socket.io',
    allowEIO3: true,
    transports: ['polling'],
    timeout: 120000,
    pingTimeout: 120000, // 60 seconds
    pingInterval: 25000, // 25 seconds
    withCredentials: true
  }),
  // connection: SocketIO('http://127.0.0.1:8550', {
  //   // path: '/document_editor/socket.io',
  //   allowEIO3: true,
  //   transports: ['polling'],
  //   timeout: 120000,
  //   pingTimeout: 120000, // 60 seconds
  //   pingInterval: 25000, // 25 seconds
  //   withCredentials: true
  // }),
  
}))

new Vue({
  router,
  store,
  vuetify: vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
