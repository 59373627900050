/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import setcolors from "../store/setcolor";
import gbfGenerate from "../globalFunctions/generateAuthorize";
import gbfSetLogin from "../globalFunctions/setLogin";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";
//import VueCookies from "vue-cookies";
const ls = new SecureLS({ isCompression: false, encodingType: "aes" });

// Vue.use(VueCookies)
// Vue.$cookies.config('1d')

Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.use(VueCookies)

export default new Vuex.Store({
  setcolors,
  gbfGenerate,
  state: {
    status_permission_outbox: "",
    status_permission_inbox: "",
    feature_status: {
      my_drive: "Y",
      my_drive_bu: "Y",
      file_s: "Y",
      inbox: "Y",
      outbox: "Y",
      shared: "Y",
      trash: "Y"
    },
    storage_usage: "0.00 MB",
    storage_usage_biz: "0.00 MB",
    storage_max: "0.00 MB",
    storage_max_personal_biz:"0.00 MB",
    // จะใช้กับอัปโหลด
    remain_storage: "0.00 MB",
    feature_onlyoffice: false,
    feature_logo: false,
    feature_inputmore: false,
    feature_renamedocument: false,
    feature_mapdrive:false,
    logo_default: "",
    setting_select_biz: false,
    service: "",
    username: "",
    accountid: "",
    loading: false,
    authorize: false,
    citizen_profile: {},
    login_default:{},
    business_profile: [],
    access_token: "",
    account_active: {},
    department_access_id: "",
    color: {},
    account_by_email: [],
    role_level: "",
    one_account_id: "",
    account_cctv: {
      status: false,
      information: {
        package: ""
      }
    },
    set_loading:false,
    status_message: "",
    triggle_timeout_otp: false,
  },
  mutations: {
    setLoading(state, data){
      state.set_loading = data
    },
    authorize_checking(state, data) {
      state.loading = true;
      state.username = "";
      state.authorize = false;
      state.citizen_profile = {};
      state.login_default = {};
      state.business_profile = [];
      (state.access_token = ""),
        (state.account_active = {
          type: "",
          directory_id: ""
        });
      state.feature_status = {
        my_drive: "Y",
        my_drive_bu: "Y",
        file_s: "Y",
        inbox: "Y",
        outbox: "Y",
        shared: "Y",
        trash: "Y"
      };
    },
    authorize_login(state, data) {
      state.loading = true;
      state.username = "";
      state.authorize = false;
      state.citizen_profile = {};
      state.login_default = {};
      state.business_profile = [];
      state.access_token = "";
      state.account_active = {
        type: "",
        directory_id: ""
      };
      state.feature_status = {
        my_drive: "Y",
        my_drive_bu: "Y",
        file_s: "Y",
        inbox: "Y",
        outbox: "Y",
        shared: "Y",
        trash: "Y"
      };
    },
    authorize_login_business_email(state, data) {
      state.loading = true;
      state.username = "";
      state.authorize = false;
      state.citizen_profile = {};
      state.login_default = {};
      state.business_profile = [];
      state.access_token = "";
      state.account_active = {
        type: "",
        directory_id: ""
      };
      state.feature_status = {
        my_drive: "Y",
        my_drive_bu: "Y",
        file_s: "Y",
        inbox: "Y",
        outbox: "Y",
        shared: "Y",
        trash: "Y"
      };
    },

    authorize_login_business_email_v2(state, data) {
      state.loading = true;
      state.username = "";
      state.authorize = false;
      state.citizen_profile = {};
      state.login_default = {};
      state.business_profile = [];
      state.access_token = "";
      state.account_active = {
        type: "",
        directory_id: ""
      };
      state.feature_status = {
        my_drive: "Y",
        my_drive_bu: "Y",
        file_s: "Y",
        inbox: "Y",
        outbox: "Y",
        shared: "Y",
        trash: "Y"
      };
    },

    authorize_login_business_email_withtoken(state, data) {
      state.loading = true;
      state.username = "";
      state.authorize = false;
      state.citizen_profile = {};
      state.login_default = {};
      state.business_profile = [];
      state.access_token = "";
      state.account_active = {
        type: "",
        directory_id: ""
      };
      state.feature_status = {
        my_drive: "Y",
        my_drive_bu: "Y",
        file_s: "Y",
        inbox: "Y",
        outbox: "Y",
        shared: "Y",
        trash: "Y"
      };
    },

    authorize_login_business_email_withdomain(state, data) {
      state.loading = true;
      state.username = "";
      state.authorize = false;
      state.citizen_profile = {};
      state.login_default = {};
      state.business_profile = [];
      state.access_token = "";
      state.account_active = {
        type: "",
        directory_id: ""
      };
      state.feature_status = {
        my_drive: "Y",
        my_drive_bu: "Y",
        file_s: "Y",
        inbox: "Y",
        outbox: "Y",
        shared: "Y",
        trash: "Y"
      };
    },

    authorize_granted(state, data) {
      state.service = "OneID";
      state.loading = false;
      state.accountid = data["user_info"]["account_id"];
      state.username = data["user_info"]["user_id"];
      state.authorize = true;
      state.citizen_profile = data["user_info"];
      state.login_default = data["login_default"];
      state.business_profile = data["biz"];
      state.access_token = data["accesstoken"];
      state.account_active = {
        type: "Citizen",
        directory_id: data["user_info"]["my_folder_id"],
        check_package: data["user_info"]["check_package"],
      };
      state.feature_status = data["feature_status"];
      state.one_account_id = data["accountid"];
      // state.color = '#00B8D'
    },

    authorize_denied(state) {
      state.service = "";
      state.loading = false;
      state.accountid = "";
      state.username = "";
      state.authorize = false;
      state.citizen_profile = {};
      state.login_default = {};
      state.business_profile = [];
      state.access_token = "";
      state.account_active = {
        type: "",
        directory_id: ""
      };
      state.feature_status = {
        my_drive: "Y",
        my_drive_bu: "Y",
        file_s: "Y",
        inbox: "Y",
        outbox: "Y",
        shared: "Y",
        trash: "Y"
      };
      state.one_account_id = "";
      state.storage_usage = "0.00 MB";
      state.storage_usage_biz = "0.00 MB";
      state.storage_max = "0.00 MB";
      state.storage_max_personal_biz = "0.00 MB";
      // state.color = ''
    },

    authorize_business_granted(state, data) {
      //console.log("data",data["feature_status"]);
      // console.log("biz",data);

      state.status_permission_inbox =
        data["business_info"]["status_permission_inbox"];
      state.status_permission_outbox =
        data["business_info"]["status_permission_outbox"];
      state.feature_status = data["feature_status"];
      state.role_level = data["user_info"]["admin"];
      // console.log(state.permission_inbox_outbox);
      state.loading = false;
      state.accountid = data["user_info"]["account_id"];
      state.account_active = {
        type: "Business",
        taxid: data["tax_id"],
        business_info: data["business_info"],
        directory_business: data["department_user"],
        directory_id: data["personal_folder_id"],
        check_package: data["business_info"]["check_package"]
      };
      //console.log("state.account_active",state.account_active);
    },

    authorize_admin_business_granted(state, data) {
      console.log("authorize_admin_business_grante");
    },

    authorize_business_email_granted(state, data) {
      console.log("authorize_business_email_granted", data);
      if (data["user_info"]["type"] === "citizen") {
        state.loading = false;
        state.service = "OneID";
        state.accountid = data["user_info"]["account_id"];
        state.username = data["user_info"]["user_id"];
        state.authorize = true;
        state.citizen_profile = data["user_info"];
        state.business_profile = [];
        state.access_token = "";
        state.account_active = {
          type: "Citizen",
          directory_id: data["user_info"]["my_folder_id"]
        };
      } else {
        state.loading = false;
        state.service = "OneMail";
        state.username = data["user_info"]["user_id"];
        state.accountid = data["user_info"]["account_id"];
        state.authorize = true;
        state.citizen_profile = data["user_info"];
        state.business_profile.push(data["business_info"]);
        state.access_token = "";
        state.account_active = {
          type: "Business",
          taxid: data["business_info"]["taxid"],
          business_info: data["business_info"],
          directory_business: [],
          directory_id: data["personal_folder_id"]
        };
      }
    },

    authorize_business_email_notype_granted(state, data) {
      console.log("authorize_business_email_notype_granted", data);

      if ("taxid" in data["business_info"]) {
        state.loading = false;
        state.service = "OneMail";
        state.username = data["user_info"]["user_id"];
        state.accountid = data["user_info"]["account_id"];
        state.authorize = true;
        state.citizen_profile = data["user_info"];
        state.business_profile.push(data["business_info"]);
        state.access_token = "";
        state.account_active = {
          type: "Business",
          taxid: data["business_info"]["taxid"],
          business_info: data["business_info"],
          directory_business: data["department_user"],
          directory_id: data["personal_folder_id"]
        };
        state.one_account_id = data["accountid"];
      } else {
        state.loading = false;
        state.service = "OneMail";
        state.accountid = data["user_info"]["account_id"];
        state.username = data["user_info"]["user_id"];
        state.authorize = true;
        state.citizen_profile = data["user_info"];
        state.business_profile = [];
        state.access_token = "";
        state.account_active = {
          type: "Citizen",
          directory_id: data["user_info"]["my_folder_id"]
        };
        state.one_account_id = data["accountid"];
      }
    },

    authorize_business_email_notype_granted_v2(state, data) {
      console.log("authorize_business_email_notype_granted_v2", data);

      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          state.username = data[i]["user_info"]["user_id"];
          state.accountid = data[i]["user_info"]["account_id"];
          state.citizen_profile = data[i]["user_info"];
          state.one_account_id = data[i]["accountid"] || "";
        }
        if ("taxid" in data[i]["business_info"]) {
          state.business_profile.push(data[i]["business_info"]);
          state.account_active = {
            type: "Business",
            taxid: data[i]["business_info"]["taxid"],
            business_info: data[i]["business_info"],
            directory_business: data[i]["department_user"],
            directory_id: data[i]["personal_folder_id"]
          };
          state.status_permission_inbox =
            data[i]["business_info"]["status_permission_inbox"];
          state.status_permission_outbox =
            data[i]["business_info"]["status_permission_outbox"];
        } else {
          state.business_profile = [];
          state.account_active = {
            type: "Citizen",
            directory_id: data[0]["user_info"]["my_folder_id"]
          };
        }
      }
      state.authorize = true;
      state.loading = false;
      state.service = "OneMail";
      state.access_token = "";
      state.account_by_email = data;
    },

    authorize_login_business_email_withdomain_granted(state, data) {
      console.log("authorize_business_email_notype_granted", data);

      if ("taxid" in data["business_info"]) {
        state.loading = false;
        state.service = "OneMail";
        state.username = data["user_info"]["user_id"];
        state.accountid = data["user_info"]["account_id"];
        state.authorize = true;
        state.citizen_profile = data["user_info"];
        state.business_profile.push(data["business_info"]);
        state.access_token = "";
        state.account_active = {
          type: "Business",
          taxid: data["business_info"]["taxid"],
          business_info: data["business_info"],
          directory_business: data["department_user"],
          directory_id: data["personal_folder_id"]
        };
        state.one_account_id = data["accountid"];
        state.status_permission_inbox =
          data["business_info"]["status_permission_inbox"];
        state.status_permission_outbox =
          data["business_info"]["status_permission_outbox"];
        state.feature_status = data["feature_status"];
        state.role_level = data["user_info"]["admin"] || "";
      } else {
        state.loading = false;
        state.service = "OneMail";
        state.accountid = data["user_info"]["account_id"];
        state.username = data["user_info"]["user_id"];
        state.authorize = true;
        state.citizen_profile = data["user_info"];
        state.business_profile = [];
        state.access_token = "";
        state.account_active = {
          type: "Citizen",
          directory_id: data["user_info"]["my_folder_id"]
        };
        state.one_account_id = data["accountid"];
        state.status_permission_inbox =
          data["business_info"]["status_permission_inbox"];
        state.status_permission_outbox =
          data["business_info"]["status_permission_outbox"];
        state.feature_status = data["feature_status"];
        state.role_level = data["user_info"]["admin"];
      }
    },

    authorize_login_business_email_withdomain_granted_migrateoneid(state, data){
      state.status_permission_inbox = data["business"]["business_info"]["status_permission_inbox"];
      state.status_permission_outbox = data["business"]["business_info"]["status_permission_outbox"];
      state.feature_status = data["business"]["feature_status"];
      state.role_level = data["business"]["user_info"]["admin"];
      state.loading = false;
      state.accountid = data["business"]["user_info"]["account_id"];
      state.account_active = {
        type: "Business",
        taxid: data["business"]["business_info"]["taxid"],
        business_info: data["business"]["business_info"],
        directory_business: data["business"]["department_user"],
        directory_id: data["business"]["personal_folder_id"],
        check_package: data["business"]["check_package"]
      };

      state.service = "OneMail_OneID";
      state.username = data["citizen"]["user_info"]["user_id"];
      state.authorize = true;
      state.citizen_profile = data["citizen"]["user_info"];
      state.login_default = data["citizen"]["login_default"];
      state.business_profile = data["citizen"]["biz"];
      state.access_token = data["citizen"]["accesstoken"];
    },

    authorize_business_denied(state) {
      state.loading = false;
      state.account_by_email = [];
      // state.accountid = state.citizen_profile.account_id
      state.feature_status = {
        my_drive: "Y",
        my_drive_bu: "Y",
        file_s: "Y",
        inbox: "Y",
        outbox: "Y",
        shared: "Y",
        trash: "Y"
      };
    },

    authorize_expired(state) {
      state.service = "";
      state.loading = false;
      state.accountid = "";
      state.username = "";
      state.authorize = false;
      state.citizen_profile = {};
      state.login_default = {};
      state.business_profile = [];
      (state.access_token = ""),
        (state.account_active = {
          type: "",
          directory_id: ""
        });
      state.feature_status = {
        my_drive: "Y",
        my_drive_bu: "Y",
        file_s: "Y",
        inbox: "Y",
        outbox: "Y",
        shared: "Y",
        trash: "Y"
      };
      state.one_account_id = "";
      state.storage_usage = "0.00 MB";
      state.storage_usage_biz = "0.00 MB";
      state.storage_max = "0.00 MB";
      state.storage_max_personal_biz = "0.00 MB"
    },

    switchaccount_citizen(state) {
      state.accountid = state.citizen_profile["account_id"];
      state.account_active = {
        type: "Citizen",
        directory_id: state.citizen_profile["my_folder_id"],
        check_package: state.citizen_profile["check_package"]
      };
      state.feature_status = {
        my_drive: "Y",
        my_drive_bu: "Y",
        file_s: "Y",
        inbox: "Y",
        outbox: "Y",
        shared: "Y",
        trash: "Y"
      };
    },

    switchaccount_business(state) {
      state.loading = true;
    },

    change_logo_default (state, new_logo) {
      state.logo_default = new_logo
      let favicon = document.querySelector('link[rel="icon"]')
      favicon.setAttribute('type', `image/png`)
      favicon.setAttribute('href', "")
    },

    authorize_login_admin_business(state) {
      console.log("authorize_login_admin_business");
    },

    switchaccount_businessonemail(state) {
      state.loading = true;
    },

    switchaccount_businessonemail_granted(state, data) {
      console.log("switchaccount_businessonemail_granted", data);

      let rawdata = state.account_by_email;
      let currentbusiness = rawdata.findIndex(
        x => x["business_info"]["business_id"] === data["data"]["business_id"]
      );
      console.log(currentbusiness);
      state.loading = false;
      state.service = "OneMail";
      state.username =
        state.account_by_email[currentbusiness]["user_info"]["user_id"];
      state.accountid =
        state.account_by_email[currentbusiness]["user_info"]["account_id"];
      state.authorize = true;
      state.citizen_profile =
        state.account_by_email[currentbusiness]["user_info"];
      state.account_active = {
        type: "Business",
        taxid:
          state.account_by_email[currentbusiness]["business_info"]["taxid"],
        business_info: state.account_by_email[currentbusiness]["business_info"],
        directory_business:
          state.account_by_email[currentbusiness]["department_user"],
        directory_id:
          state.account_by_email[currentbusiness]["personal_folder_id"]
      };
    },

    department_access_id(state, data) {
      state.department_access_id = data;
    },

    check_storage_success(state, data) {
      // console.log("aaa----------",data);
      //console.log("aaa",data[0]["storage"]);

      state.storage_usage = data[0]["used_storage"];
      if (data[0]["used_storage_business"] !== undefined) {
        state.storage_usage_biz = data[0]["used_storage_business"];
      } else {
        state.storage_usage_biz = "0.00 MB";
      }
      state.storage_max = data[0]["storage"];
      state.storage_max_personal_biz= data[0]["account_user_storage"]
      // จะใช้กับอัปโหลด
      state.remain_storage = data[0]["remain_storage"];
    },

    check_storage_fail(state) {
      state.storage_usage = "0.00 MB";
      state.storage_max = "0.00 MB";
      state.storage_max_personal_biz = "0.00 MB"
    },

    show_loading(state, data) {
      state.loading = data;
    },

    check_color(state, colorGroup) {
      state.color = colorGroup;
      //  console.log(state.color);
    },

    check_feature(state, data) {
      state.feature_status = data;
    },

    set_loading(state, data) {
      state.loading = data;
      // console.log("setloading-----2---------");
    },

    updatepermissioninoutbox_business_manage (state, permission) {
      if(permission.type_box === 'inbox'){
        state.status_permission_inbox = permission.type_permission
      }else{
        state.status_permission_outbox = permission.type_permission
      }
      
    },

    triggle_otp_botonebox (state, message) {
      state.status_message = message.status 
      state.triggle_timeout_otp = message.show

    },

    // cctv_accessChecker(_state) {
    //   _state.account_cctv = {
    //     status: false,
    //     information: {
    //       package: ""
    //     }
    //   };
    // },

    cctv_accessGrant(_state, _data) {
      _state.account_cctv = {
        status: true,
        information: {
          package: _data.package
        }
      };
    },

    cctv_accessDenied(_state) {
      _state.account_cctv = {
        status: false,
        information: {
          package: ""
        }
      };
    }
  },
  actions: {
    //ตรวจสอบ login เมื่อมีการ Refresh
    async check_storage({ commit }, data) {
      let payload = {
        account_id: data,
        convert_storage : "True"
      };
      //console.log("paycheck",payload);
      let auth = await gbfGenerate.generateToken();
      var result = await axios.post(
        // process.env.VUE_APP_SERVICE_AUTHORIZE_BUSINESS + "/api/used_storage_v2",
        process.env.VUE_APP_SERVICE_AUTHORIZE_BUSINESS_CHECK_STORAGE + "/api/check_used_storage",
        payload,{headers: { Authorization: auth.code },}
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            // console.log("result----------",result.data.result[0].used_storage)
            // console.log("result----------",result.data.result[0].used_storage_business)

            commit("check_storage_success", result.data.result);

            resolve({ status: "Check Storage Success", msg: "OK" });
          } else {
            commit("check_storage_fail");
            resolve({ status: "Check Storage Fail", msg: "FAIL" });
          }
        } catch (ex) {
          commit("check_storage_fail");
          reject(ex);
        }
      });
    },

    async authorize_checking({ commit, dispatch }, data) {
      commit("authorize_checking");
      //if (localStorage.hasOwnProperty("token")) {
      if (sessionStorage.getItem("checklogin") == 'false') {
        if (sessionStorage.getItem("token") !== null && sessionStorage.getItem("token_auth") !== null) {
          //let authorize = { accesstoken: localStorage.getItem("token") };
          //console.log(VueCookies.get("token"));
          let authorize;
          if(sessionStorage.getItem("token") == "null" ){
            let user_id = CryptoJS.AES.decrypt(sessionStorage.getItem("user_id"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
            user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
            // authorize = { user_id: sessionStorage.getItem("user_id") };
            authorize = { user_id: user_id };
            // let biz_user_id = sessionStorage.getItem("biz_active");
            // authorize = { user_id:  biz_user_id.user_id };
          }else{
            // authorize = { accesstoken: VueCookies.get("token") };
            authorize = { accesstoken: window.atob(sessionStorage.getItem("token")) };
          }
          var result = await axios.post(
            process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/getprofile",
            authorize
          );
  
          try {
            if (result.data.status === "OK") {
              // เช็คแจ้งเตือนการตั้งค่าบริษัทเริ่มต้น ด้วย state
              // let login_default = result.data.result.login_default;
              // ไม่เคยตั้ง(ค่า null), ผู้ใช้งานทั่วไป(ค่าว่าง) , ไดร์ฟบริษัท(ข้อมูลบริษัท)
              if(result.data.result.login_default === null){
                this.state.setting_select_biz = true;
              }else{
                this.state.setting_select_biz = false;
              }
              // console.log("setting_select_biz",this.state.setting_select_biz);
              // console.log("setting_select_biz",login_default);
              // console.log("result",result.data.result)
              dispatch("authorize_granted", result.data.result);
              return new Promise((resolve, reject) => {
                resolve({ status: "Access Granted", msg: "OK", code: "" });
              });
            } else {
              commit("authorize_denied");
              return new Promise((resolve, reject) => {
                resolve({
                  status: "Access Denied",
                  msg: result.data.errorCode + ": " + result.data.errorMessage,
                  code: result.data.errorCode
                });
              });
            }
          } catch (ex) {
            commit("authorize_denied");
            return new Promise((resolve, reject) => {
              resolve({ status: "Access Denied", msg: ex });
            });
          }
        } else {
          commit("authorize_denied");
          return new Promise((resolve, reject) => {
            resolve({ status: "Access Denied", msg: "Session Expired" });
          });
        }
      } else {
        if (VueCookies.isKey("token") === true && VueCookies.isKey("token_auth") === true) {
          //let authorize = { accesstoken: localStorage.getItem("token") };
          //console.log(VueCookies.get("token"));
          let authorize;
          if(VueCookies.get("token") === '' || VueCookies.get("token") == null ){
            
            let user_id = CryptoJS.AES.decrypt(VueCookies.get("user_id"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
            user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
            authorize = { user_id: user_id };
            // let biz_user_id = sessionStorage.getItem("biz_active");
            // authorize = { user_id:  biz_user_id.user_id };
          }else{
            // authorize = { accesstoken: VueCookies.get("token") };
            authorize = { accesstoken: window.atob(VueCookies.get("token")) };
            // authorize = { accesstoken: sessionStorage.getItem("token") };
          }
          // let authorize = { accesstoken: VueCookies.get("token") };
          var result = await axios.post(
            process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/getprofile",
            authorize
          );
  
          try {
            if (result.data.status === "OK") {
              // เช็คแจ้งเตือนการตั้งค่าบริษัทเริ่มต้น ด้วย state
              // let login_default = result.data.result.login_default;
              // ไม่เคยตั้ง(ค่า null), ผู้ใช้งานทั่วไป(ค่าว่าง) , ไดร์ฟบริษัท(ข้อมูลบริษัท)
              if(result.data.result.login_default === null){
                this.state.setting_select_biz = true;
              }else{
                this.state.setting_select_biz = false;
              }
              //console.log("result",result.data.result)
              dispatch("authorize_granted", result.data.result);
              return new Promise((resolve, reject) => {
                resolve({ status: "Access Granted", msg: "OK", code: "" });
              });
            } else {
              commit("authorize_denied");
              return new Promise((resolve, reject) => {
                resolve({
                  status: "Access Denied",
                  msg: result.data.errorCode + ": " + result.data.errorMessage,
                  code: result.data.errorCode
                });
              });
            }
          } catch (ex) {
            commit("authorize_denied");
            return new Promise((resolve, reject) => {
              resolve({ status: "Access Denied", msg: ex });
            });
          }
        } else {
          commit("authorize_denied");
          return new Promise((resolve, reject) => {
            resolve({ status: "Access Denied", msg: "Session Expired" });
          });
        }
      }
    },

    //ตรวจสอบ login เมื่อมีการกรอก Username Password
    async authorize_login({ commit, dispatch }, data) {
      commit("authorize_login");
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_CITIZEN + "/api/login",
        data
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            console.log("login",result.data.result)
            let encodetoken = window.btoa(result.data.result["accesstoken"])
            let user_id_key = (Math.random() + 1).toString(36).substring(7);
            VueCookies.set("user_key", user_id_key);
            VueCookies.set("token", encodetoken);
            sessionStorage.setItem("token", encodetoken)
            sessionStorage.setItem("token_auth", true)
            // localStorage.setItem("token", encodetoken);
            
            // localStorage.setItem("token", result.data.result["accesstoken"]);
            // VueCookies.set('token', result.data.result["accesstoken"]);
            VueCookies.set('token_auth', true);
            VueCookies.set("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("thai_email", result.data.result.user_info["thai_email"]);
            // localStorage.setItem("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set('thai_email', CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("username", result.data.result.user_info["username"]);
            //เดิม localStorage.setItem("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("user_id", result.data.result.user_info["user_id"]);
            // localStorage.setItem("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set('user_id', CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            dispatch("authorize_granted", result.data.result);
            if (result.data.result.login_default === "") {
              dispatch("authorize_checking");
            }
            resolve({ status: "Access Granted", msg: "OK", code: "" });
          } else {
            dispatch("authorize_denied");
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage,
              code: result.data.errorCode
            });
          }
        } catch (ex) {
          dispatch("authorize_denied");
          reject(ex);
        }
      });
    },
    //ตรวจสอบ login เมื่อมีการกรอก Username Password ของ botonebox ขอ OTP แบบไม่เชื่อม oneid (ยังไม่ได้ใช้งาน)
    async authorize_login_nooneid_with_botonebox({ commit, dispatch }, data) { 
      commit("authorize_login");
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v2/login",data
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            let encodetoken = window.btoa(result.data.result["accesstoken"])
            let user_id_key = (Math.random() + 1).toString(36).substring(7);
            // localStorage.setItem("token", encodetoken);
            VueCookies.set("user_key", user_id_key);
            VueCookies.set("accountid", CryptoJS.AES.encrypt(window.btoa(result.data.result.accountid), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // เดิม localStorage.setItem("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            dispatch("authorize_granted", result.data.result);
            if (result.data.result.login_default === "") {
              dispatch("authorize_checking");
            }
            resolve({ status: "Access Granted", msg: "OK", code: "" });
          } else {
            console.log("====,,,,");
            dispatch("authorize_denied");
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage,
              code: result.data.errorCode
            });
          }
        } catch (ex) {
          dispatch("authorize_denied");
          reject(ex);
        }
      });
    },

    //ตรวจสอบ login เมื่อมีการกรอก Username Password ของ botonebox ขอ OTP แบบไม่เชื่อม oneid
    async authorize_login_with_botonebox({ commit, dispatch }, data) {
      //commit("authorize_login");
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v2/login",data
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            gbfSetLogin.setValueLogin(result);
            resolve({ status: "Access Granted", msg: "OK", code: "", result: result.data.result  });
          } else {
            console.log("====,,,,", result.data);
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage,
              code: result.data.errorCode
            });
          }
        } catch (ex) {
          reject(ex);
        }
      });
    },

    //ตรวจสอบ OTP เมื่อมีการกรอก ของ botonebox 
    async verified_otp_with_botonebox({ commit, dispatch }, data ) {
      var response = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v1/verify/otp",data.payload 
      );
      return new Promise((resolve, reject) => {
        try {
          if (response.data.status === "OK") {
            let encodetoken = window.btoa(data.infor_granted["accesstoken"])
            let user_id_key = (Math.random() + 1).toString(36).substring(7);
            //localStorage.setItem("token", encodetoken); เก่า
            VueCookies.set("token", encodetoken);
            VueCookies.set("user_key", user_id_key);
            VueCookies.set("accountid", CryptoJS.AES.encrypt(window.btoa(data.infor_granted.accountid), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("username", CryptoJS.AES.encrypt(window.btoa(data.infor_granted.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // VueCookies.set("feature_onlyoffice",false)
            this.state.feature_onlyoffice = false;
            this.state.feature_inputmore = false;
            this.state.feature_renamedocument = false;
            this.state.feature_mapdrive = false;
            console.log("feature_onlyoffice", this.state.feature_onlyoffice);
            // localStorage.setItem("thai_email", CryptoJS.AES.encrypt(window.btoa(data.infor_granted.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("thai_email", CryptoJS.AES.encrypt(window.btoa(data.infor_granted.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("user_id", CryptoJS.AES.encrypt(window.btoa(data.infor_granted.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("user_id", CryptoJS.AES.encrypt(window.btoa(data.infor_granted.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // เดิม localStorage.setItem("username", CryptoJS.AES.encrypt(window.btoa(data.infor_granted.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            dispatch("authorize_granted", data.infor_granted);
            resolve({ status: "OK", message: "Verified", time_using: response.data.expired_login_dtm });
          } else {
            dispatch("authorize_denied");
            resolve({ status: "INVALID OTP", message: "Invalid OTP provided" });
          }
        } catch (ex) {
          dispatch("authorize_denied");
          reject(ex);
        }
      });
    },

     //ตรวจสอบ login เมื่อมีการกรอก Username Password แบบไม่เชื่อม oneid
    async authorize_login_nooneid_shareupload_dedicated({ commit, dispatch }, data) {
      commit("authorize_login");
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v3/login",data
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            console.log("====");
            console.log("login",result.data.result)
            let encodetoken = window.btoa(result.data.result["accesstoken"])
            let user_id_key = (Math.random() + 1).toString(36).substring(7);
            VueCookies.set("user_key", user_id_key);
            VueCookies.set("token", encodetoken);
            sessionStorage.setItem("token", encodetoken);
            //localStorage.setItem("token", encodetoken);
            // VueCookies.set("feature_onlyoffice",false);
            this.state.feature_onlyoffice = false;
            this.state.feature_logo = false // MAI ADD
            this.state.feature_inputmore = false; // MAI ADD
            this.state.feature_renamedocument = false;
            this.state.logo_default = ""
            console.log("feature_onlyoffice", this.state.feature_onlyoffice);
            // localStorage.setItem("token", result.data.result["accesstoken"]);
            // VueCookies.set('token', result.data.result["accesstoken"], 60 * 60 * 24);
            VueCookies.set('token_auth', true, 60 * 60 * 24);
            // VueCookies.set("accountid", result.data.result.accountid );
            VueCookies.set("accountid", CryptoJS.AES.encrypt(window.btoa(result.data.result.accountid), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("thai_email", result.data.result.user_info["thai_email"]);
            VueCookies.set('thai_email', CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("username", result.data.result.user_info["username"]);
            // เดิม localStorage.setItem("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("user_id", result.data.result.user_info["user_id"]);
            VueCookies.set('user_id', CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            dispatch("authorize_granted", result.data.result);
            if (result.data.result.login_default === "") {
              dispatch("authorize_checking");
            }
            resolve({ status: "Access Granted", msg: "OK", code: "" });
          } else {
            console.log("====,,,,");
            dispatch("authorize_denied");
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage,
              code: result.data.errorCode,
              error_data: result.data,
            });
          }
        } catch (ex) {
          dispatch("authorize_denied");
          reject(ex);
        }
      });
    },

    //ตรวจสอบ login เมื่อมีการกรอก Username Password แบบไม่เชื่อม oneid
    async authorize_login_nooneid({ commit, dispatch }, data) {
      commit("authorize_login");
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v2/login",data
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            console.log("====");
            console.log("login",result.data.result)
            let encodetoken = window.btoa(result.data.result["accesstoken"])
            let user_id_key = (Math.random() + 1).toString(36).substring(7);
            VueCookies.set("user_key", user_id_key);
            VueCookies.set("token", encodetoken);
            sessionStorage.setItem("token", encodetoken);
            //localStorage.setItem("token", encodetoken);
            // VueCookies.set("feature_onlyoffice",false);
            this.state.feature_onlyoffice = false;
            this.state.feature_logo = false // MAI ADD
            this.state.feature_inputmore = false; // MAI ADD
            this.state.feature_renamedocument = false;
            this.state.logo_default = ""
            console.log("feature_onlyoffice", this.state.feature_onlyoffice);
            // localStorage.setItem("token", result.data.result["accesstoken"]);
            // VueCookies.set('token', result.data.result["accesstoken"], 60 * 60 * 24);
            VueCookies.set('token_auth', true, 60 * 60 * 24);
            // VueCookies.set("accountid", result.data.result.accountid );
            VueCookies.set("accountid", CryptoJS.AES.encrypt(window.btoa(result.data.result.accountid), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("thai_email", result.data.result.user_info["thai_email"]);
            VueCookies.set('thai_email', CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("username", result.data.result.user_info["username"]);
            // เดิม localStorage.setItem("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("user_id", result.data.result.user_info["user_id"]);
            VueCookies.set('user_id', CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            dispatch("authorize_granted", result.data.result);
            if (result.data.result.login_default === "") {
              dispatch("authorize_checking");
            }
            resolve({ status: "Access Granted", msg: "OK", code: "" });
          } else {
            console.log("====,,,,");
            dispatch("authorize_denied");
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage,
              code: result.data.errorCode,
              error_data: result.data,
            });
          }
        } catch (ex) {
          dispatch("authorize_denied");
          reject(ex);
        }
      });
    },

    //ตรวจสอบ login เมื่อมีการกรอก onecollab_email
    async authorize_onecollab_email({ commit, dispatch }, data) {
      commit("authorize_login");
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/login_with_collaboration",data
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            console.log("====");
            console.log("login",result.data.result)
            let encodetoken = window.btoa(result.data.result["accesstoken"])
            let user_id_key = (Math.random() + 1).toString(36).substring(7);
            VueCookies.set("user_key", user_id_key);
            // localStorage.setItem("token", encodetoken);
            VueCookies.set("token", encodetoken);
            // localStorage.setItem("token", result.data.result["accesstoken"]);
            // VueCookies.set('token', result.data.result["accesstoken"], 60 * 60 * 24);
            VueCookies.set('token_auth', true, 60 * 60 * 24);
            // VueCookies.set("accountid", result.data.result.accountid );
            VueCookies.set("accountid", CryptoJS.AES.encrypt(window.btoa(result.data.result.accountid), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("thai_email", result.data.result.user_info["thai_email"]);
            VueCookies.set("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("username", result.data.result.user_info["username"]);
            // เดิม localStorage.setItem("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("user_id", result.data.result.user_info["user_id"]);
            VueCookies.set("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            dispatch("authorize_granted", result.data.result);
            resolve({ status: "Access Granted", msg: "OK", code: "" });
          } else {
            console.log("====,,,,");
            dispatch("authorize_denied");
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage,
              code: result.data.errorCode
            });
          }
        } catch (ex) {
          dispatch("authorize_denied");
          reject(ex);
        }
      });
    },

    //ตรวจสอบ login เมื่อมีการกรอก Mobile OTP
    async authorize_login_mobile_otp({ commit, dispatch }, data) {
      commit("authorize_login");
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/login_by_mobile",data
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            console.log("====");
            console.log("login",result.data.result)
            let encodetoken = window.btoa(result.data.result["accesstoken"])
            let user_id_key = (Math.random() + 1).toString(36).substring(7);
            VueCookies.set("user_key", user_id_key);
            // localStorage.setItem("token", encodetoken);
            VueCookies.set("token", encodetoken);
            // localStorage.setItem("token", result.data.result["accesstoken"]);
            // VueCookies.set('token', result.data.result["accesstoken"], 60 * 60 * 24);
            VueCookies.set('token_auth', true, 60 * 60 * 24);
            // VueCookies.set("accountid", result.data.result.accountid );
            VueCookies.set("accountid", CryptoJS.AES.encrypt(window.btoa(result.data.result.accountid), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("thai_email", result.data.result.user_info["thai_email"]);
            // localStorage.setItem("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("username", result.data.result.user_info["username"]);
            // เดิม localStorage.setItem("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("user_id", result.data.result.user_info["user_id"]);
            VueCookies.set("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            dispatch("authorize_granted", result.data.result);
            if (result.data.result.login_default === "") {
              dispatch("authorize_checking");
            }
            resolve({ status: "Access Granted", msg: "OK", code: "" });
          } else {
            console.log("====,,,,");
            dispatch("authorize_denied");
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage,
              code: result.data.errorCode
            });
          }
        } catch (ex) {
          dispatch("authorize_denied");
          reject(ex);
        }
      });
    },

    //ตรวจสอบ login ของ Business (ไม่มีการนำไปใช้งาน)
    async authorize_login_business({ commit }, data) {
      commit("authorize_login");
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_CITIZEN + "/api/login",
        data
      );
      return new Promise((resolve, reject) => {
        try {
          console.log("testloginbiz", result);
          if (result.data.status === "OK") {
            console.log("testloginbiz", result);

            commit("authorize_granted", result.data.result);
            resolve({ status: "Access Granted", msg: "OK" });
          } else {
            commit("authorize_denied");
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage
            });
          }
        } catch (ex) {
          commit("authorize_denied");
          reject(ex);
        }
      });
    },

    //ตรวจสอบ login ของ Business ของ One Mail
    async authorize_login_business_email({ commit }, data) {
      commit("authorize_login_business_email");
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_EMAIL +
          "/api/v2/encode/login_byemail",
        data
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            sessionStorage.setItem('loginonemail', true);
            gbfSetLogin.setValueLogin(result);
            commit(
              "authorize_business_email_notype_granted",
              result.data.result
            );
            resolve({ status: "Access Granted", msg: "OK" });
          } else {
            console.log(result);
            commit("authorize_denied");
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage
            });
          }
        } catch (ex) {
          console.log(ex);
          commit("authorize_denied");
          reject(ex);
        }
      });
    },

    async authorize_login_business_email_v2({ commit }, data) {
      commit("authorize_login_business_email_v2");
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_EMAIL +
          "/api/v2/encode/login_byemail",
        data
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            sessionStorage.setItem('loginonemail', true);
            gbfSetLogin.setValueLogin(result);
            commit(
              "authorize_business_email_notype_granted_v2",
              result.data.result
            );
            resolve({ status: "Access Granted", msg: "OK" });
          } else {
            console.log(result);
            commit("authorize_denied");
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage
            });
          }
        } catch (ex) {
          console.log(ex);
          commit("authorize_denied");
          reject(ex);
        }
      });
    },

    //ตรวจสอบ login ของ Business ของ One Mail ด้วย Token
    async authorize_login_business_email_withtoken({ commit }, data) {
      commit("authorize_login_business_email_withtoken");
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_EMAIL +
          "/api/v3/encode/login_byemail",
        data
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            sessionStorage.setItem('loginonemail', true);
            gbfSetLogin.setValueLogin(result);
            commit(
              "authorize_business_email_notype_granted_v2",
              result.data.result
            );
            resolve({ status: "Access Granted", msg: "OK" });
          } else {
            console.log(result);
            commit("authorize_denied");
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage
            });
          }
        } catch (ex) {
          console.log(ex);
          commit("authorize_denied");
          reject(ex);
        }
      });
    },

    //ตรวจสอบ login ของ Business ของ One Mail
    async authorize_login_business_email_withdomain({ commit }, data) {
      commit("authorize_login_business_email_withdomain");
      try {
        var result = await axios.post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_EMAIL +
            "/api/v4/encode/login_byemail",
          data
        );
        if (result.data.status === "OK") {
          sessionStorage.setItem('loginonemail', true);
          gbfSetLogin.setValueLogin(result);
          commit("authorize_login_business_email_withdomain_granted", result.data.result);
          return new Promise((resolve, reject) => {
            resolve({ status: "Access Granted", msg: "OK" });
          });
        } else {
          commit("authorize_denied");
          return new Promise((resolve, reject) => {
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage
            });
          });
        }
      } catch (ex) {
        console.log(ex);
        commit("authorize_denied");
        return new Promise((resolve, reject) => {
          reject(ex);
        });
      }
    },

    //ตรวจสอบ login ของ Business ของ One Mail แบบเชื่อม Oneid
    async authorize_login_business_email_withdomain_migrateoneid({ commit }, data) {
      commit("authorize_login_business_email_withdomain");
      try {
        var result = await axios.post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_EMAIL +
            "/api/v4/encode/login_byemail",
          data
        );
        if (result.data.status === "OK") {
          //ตรวจสอบว่า  User มี Business หรือไม่
          if(result.data.result.biz.length > 0){
            let payload = {
              taxid: result.data.result.biz[0].id_card_num || "" ,
              accesstoken: result.data.result.accesstoken,
              biz_id: result.data.result.biz[0].id || ""          
            };
          let call_loginbusiness = await axios.post(process.env.VUE_APP_SERVICE_AUTHORIZE_BUSINESS + "/api/V3/login_business", payload);
            if (call_loginbusiness.data.status === "OK") {
              let objdatalogin = {
                citizen: result.data.result,
                business: call_loginbusiness.data.result
              }
              sessionStorage.setItem('loginonemail', true);
              gbfSetLogin.setValueLogin(result);
              // VueCookies.set('token', result.data.result["accesstoken"]);
              // VueCookies.set('token', window.btoa(result.data.result["accesstoken"]));
              // VueCookies.set('token_auth', true);
              commit("authorize_login_business_email_withdomain_granted_migrateoneid", objdatalogin);
              return new Promise((resolve, reject) => {
                resolve({ status: "Access Granted", msg: "OK" });
              });

            } else {
              commit("authorize_denied");
              return new Promise((resolve, reject) => {
                resolve({
                  status: "Access Denied",
                  msg: call_loginbusiness.data.errorCode + ": " + call_loginbusiness.data.errorMessage
                });
              }); 
            }
          } else {
            commit("authorize_denied");
            return new Promise((resolve, reject) => {
              resolve({
                status: "Access Denied",
                msg: "Company Not Found"
              });
            });
          }
        } else {
          commit("authorize_denied");
          return new Promise((resolve, reject) => {
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage
            });
          });
        }
      } catch (ex) {
        console.log(ex);
        commit("authorize_denied");
        return new Promise((resolve, reject) => {
          reject(ex);
        });
      }
    },

    // login business sharedtoken
    async authorize_login_business_sharedtoken({ commit }, data) {
      let auth = await gbfGenerate.generateToken();
      var result = await axios.get(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/login/sharedtoken?share_token=" + data.shared_token,
        {headers: { Authorization: auth.code },}
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            let encodetoken = window.btoa(result.data.result["accesstoken"])
            //localStorage.setItem("token", encodetoken);
            // localStorage.setItem("token", result.data.result["accesstoken"]);
            VueCookies.set('token', window.btoa(result.data.result["accesstoken"]), "1h");
            // VueCookies.set('token', result.data.result["accesstoken"], "1h");
            VueCookies.set('token_auth', true);
            // VueCookies.set("accountid", result.data.result["accountid"]);
            VueCookies.set("accountid", CryptoJS.AES.encrypt(window.btoa(result.data.result.accountid), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("accountid_userinfo", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info.account_id), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("thai_email", result.data.result.user_info["thai_email"]);
            // localStorage.setItem("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("username", result.data.result.user_info["username"]);
            // เดิม localStorage.setItem("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            commit("authorize_granted", result.data.result);
            resolve({ status: "Access Granted", msg: "OK" });
          } else {
            commit("authorize_denied");
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage
            });
          }
        } catch (ex) {
          commit("authorize_denied");
          reject(ex);
        }
      });
    },
    
     //ตรวจสอบ login oneplatform
     async authorize_login_oneplatform({ commit, dispatch }, data) {
      commit("authorize_login");
      let auth = await gbfGenerate.generateToken();
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_CITIZEN + "/api/oneplatform/login",
        data,{headers: { Authorization: auth.code },}
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            console.log("====");
            console.log("login",result.data.result)
            let encodetoken = window.btoa(result.data.result["accesstoken"])
            let user_id_key = (Math.random() + 1).toString(36).substring(7);
            VueCookies.set("user_key", user_id_key);
            VueCookies.set("token", encodetoken);
            sessionStorage.setItem("token", encodetoken);
            //localStorage.setItem("token", encodetoken);
            // localStorage.setItem("token", result.data.result["accesstoken"]);
            // VueCookies.set('token', result.data.result["accesstoken"], 60 * 60 * 24);
            VueCookies.set('token_auth', true, 60 * 60 * 24);
            // VueCookies.set("accountid", result.data.result.accountid );
            VueCookies.set("accountid", CryptoJS.AES.encrypt(window.btoa(result.data.result.accountid), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("thai_email", result.data.result.user_info["thai_email"]);
            // localStorage.setItem("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set('thai_email', CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("username", result.data.result.user_info["username"]);
            VueCookies.set('user_id', CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // เดิม localStorage.setItem("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("user_id", result.data.result.user_info["user_id"]);
            // localStorage.setItem("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            dispatch("authorize_granted", result.data.result);
            resolve({ status: "Access Granted", msg: "OK", code: "" , data:result.data.result});
          } else {
            dispatch("authorize_denied");
            if(result.data.errorMessage === 'Request to OneID was problem'){
              resolve({
                status: "Request to OneID was problem",
                msg: result.data.errorCode + ": " + result.data.errorMessage,
                code: result.data.errorCode,
                data:result.data.result
              });
            }else{
              resolve({
                status: "Access Denied",
                msg: result.data.errorCode + ": " + result.data.errorMessage,
                code: result.data.errorCode,
                data:result.data.result
              });
            }
          }
        } catch (ex) {
          dispatch("authorize_denied");
          reject(ex);
        }
      });
    },
       // login business Access Tokens Oneplatfrom
    async authorize_login_business_accesstoken({ commit }, data) {
      let auth = await gbfGenerate.generateToken();
      var result = await axios.get(
        // https://onebox.thaimediafund.or.th/login/oneplatform?share_token=<shared_token>
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/login/sharedtoken?accesstoken=" + data.shared_token,
        {headers: { Authorization: auth.code },}
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            let encodetoken = window.btoa(result.data.result["accesstoken"])
            //localStorage.setItem("token", encodetoken);
            // localStorage.setItem("token", result.data.result["accesstoken"]);
            VueCookies.set('token', window.btoa(result.data.result["accesstoken"]), "1h");
            // VueCookies.set('token', result.data.result["accesstoken"], "1h");
            VueCookies.set('token_auth', true);
            // VueCookies.set("accountid", result.data.result["accountid"]);
            VueCookies.set("accountid", CryptoJS.AES.encrypt(window.btoa(result.data.result.accountid), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("thai_email", result.data.result.user_info["thai_email"]);
            // localStorage.setItem("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            // localStorage.setItem("username", result.data.result.user_info["username"]);
            // เดิม localStorage.setItem("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            commit("authorize_granted", result.data.result);
            resolve({ status: "Access Granted", msg: "OK" });
          } else {
            commit("authorize_denied");
            if(result.data.errorMessage === 'Request to OneID was problem'){
              resolve({
                status: "Request to OneID was problem",
                msg: result.data.errorCode + ": " + result.data.errorMessage
              });
            }else{
              resolve({
                status: "Access Denied",
                msg: result.data.errorCode + ": " + result.data.errorMessage
              });
            }
          }
        } catch (ex) {
          commit("authorize_denied");
          reject(ex);
        }
      });
    },    

    async authorize_granted({ commit, dispatch }, data) {
      commit("authorize_granted", data);
      // await dispatch("cctv_accessChecker", data.user_info.user_id);
      return new Promise((resolve, reject) => {
        resolve("User Authorize Access Grant");
      });
    },

    async authorize_denied({ commit, dispatch }, data) {
      commit("authorize_denied");
      sessionStorage.removeItem("biz_active");
      await dispatch("cctv_accessDenied");
      return new Promise((resolve, reject) => {
        resolve("User Authorize Access Deny");
      });
    },

    async authorize_expired({ commit }, data) {
      commit("authorize_expired");
      localStorage.removeItem("token");
      VueCookies.remove("token");
      VueCookies.remove("token_auth");
      VueCookies.remove("checklogin");
      VueCookies.remove("check_btn_login_new");
      VueCookies.remove("check_alert_email");
      VueCookies.remove("check_mohpromt");
      VueCookies.remove("taxbox");
      sessionStorage.removeItem("biz_active");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("token_auth");
      sessionStorage.removeItem("checklogin");
      sessionStorage.removeItem("user_id");
      localStorage.removeItem("user_id");
      localStorage.removeItem("thai_email");
      localStorage.removeItem("username");
      //localStorage.removeItem("username_otp"); // botonebox
      VueCookies.remove('username_otp'); // แก้ใหม่ ย้ายไปใน cookies
      VueCookies.remove('user_en');
      VueCookies.remove("azure");
      VueCookies.remove("biz_active");
      VueCookies.remove("oneplatfrom");
      VueCookies.remove("check_citizen_oneplat");
      VueCookies.remove("feature_onlyoffice");
      VueCookies.remove("feature_inputmore");
      VueCookies.remove("feature_renamedocument");
      VueCookies.remove("feature_google");
      VueCookies.remove("ggdrive");
      VueCookies.remove("login_datetime"); // botonebox 
      VueCookies.remove("continue_otp"); // botonebox
      VueCookies.remove("reason_otp"); // botonebox
      VueCookies.remove("data_id"); // botonebox
      VueCookies.remove("taxbox_check"); // botonebox
      VueCookies.remove("iconeva"); // evaluate
      return new Promise((resolve, reject) => {
        resolve("User Authorize Expired");
      });
    },

    //สลับ Account ไปใช้ Citizen
    switchaccount_citizen({ commit }) {
      // VueCookies.set("feature_onlyoffice",false)
      this.state.feature_inputmore = false
      this.state.feature_onlyoffice = false
      this.state.feature_renamedocument = false
      this.state.feature_mapdrive = false
      console.log("feature_onlyoffice", this.state.feature_onlyoffice);
      // Default logo BOX
      this.state.feature_logo = false
      this.state.logo_default = ""
      let favicon = document.querySelector('link[rel="icon"]')
      favicon.setAttribute('type', `image/png`)
      favicon.setAttribute('href', "")
      // ///////////////////////
      commit("switchaccount_citizen");
      return new Promise((resolve, reject) => {
        resolve("switchaccount_citizen");
      });
    },

    //สลับ Account ไปใช้ Business
    async switchaccount_business({ commit, dispatch }, data) {
      console.log("เข้าอันนี้โว้ยยยยยยยยยยยยยยยยยย");
      // console.log("data",data);
      commit("switchaccount_business");
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_BUSINESS + "/api/V3/login_business",
        data
      );
      try {
        if (result.data.status === "OK") {
          console.log("result",result.data.result.business_info.business_feature);
          let business_feature = result.data.result.business_info.business_feature;
          if(business_feature.length !== 0){
            let objIndex = business_feature.findIndex( (obj) => obj.feature === "only_office" );
                 console.log("objIndex",objIndex);
                 if(objIndex !== -1){
                  if(business_feature[objIndex].feature_status === "Y"){
                    // VueCookies.set("feature_onlyoffice",true)
                    this.state.feature_onlyoffice = true
                    console.log("feature_onlyoffice", this.state.feature_onlyoffice);
                  }else{
                    // VueCookies.set("feature_onlyoffice",false)
                    this.state.feature_onlyoffice = false
                    console.log("feature_onlyoffice", this.state.feature_onlyoffice);
                  }
                 }

            let objIndex_mapdrive = business_feature.findIndex( (obj) => obj.feature === "mapdrive" );
            if(objIndex_mapdrive !== -1){
              if(business_feature[objIndex_mapdrive].feature_status === "Y"){
                this.state.feature_mapdrive = true
                console.log("feature_mapdrive", this.state.feature_mapdrive);
              }else{
                this.state.feature_mapdrive = false
                console.log("feature_mapdrive", this.state.feature_mapdrive);
              }
            }


            let inputmoreIndex = business_feature.findIndex( (obj) => obj.feature === "inputmore" );
            if(inputmoreIndex !== -1){
              if(business_feature[inputmoreIndex].feature_status === "Y"){
                this.state.feature_inputmore = true
              }else{
                this.state.feature_inputmore = false
              }
            } else {
              this.state.feature_inputmore = false
            }

            // LOGO
            let objectIndex_logo = business_feature.findIndex((obj) => obj.feature === 'business_logo')
            if(objectIndex_logo !== -1){
              if(business_feature[objectIndex_logo].feature_status === "Y"){
                try{
                this.state.feature_logo = true
                if(business_feature[objectIndex_logo].logo === 'default' || business_feature[objectIndex_logo].logo === '' ){
                  this.state.logo_default = 'default'
                  let favicon = document.querySelector('link[rel="icon"]')
                  favicon.setAttribute('type', `image/png`)
                  favicon.setAttribute('href', "")
                } else {
                  console.log('ทำไม ไม่เปลี่ยนอีกแล้วว')
                  this.state.logo_default = business_feature[objectIndex_logo].logo
                  let favicon = document.querySelector('link[rel="icon"]')
                  favicon.setAttribute('type', `image/${this.state.logo_default.split(';')[0].split('/')[1]}`)
                  favicon.setAttribute('href', this.state.logo_default)
                }   
                } catch (err) {
                  console.log("Working logoEER  ")
                  this.state.logo_default = ''
                  let favicon = document.querySelector('link[rel="icon"]')
                  favicon.setAttribute('type', `image/png`)
                  favicon.setAttribute('href', "")
                }
              }else{
                this.state.feature_logo = false
                this.state.logo_default = ''
                let favicon = document.querySelector('link[rel="icon"]')
                favicon.setAttribute('type', `image/png`)
                favicon.setAttribute('href', "")
              }
            }

            let objIndex_renamedocument = business_feature.findIndex( (obj) => obj.feature === "Rename document" );
            if(objIndex_renamedocument !== -1){
              if(business_feature[objIndex_renamedocument].feature_status === "Y"){
                this.state.feature_renamedocument = true
                console.log("feature_renamedocument", this.state.feature_renamedocument);
              }else{
                this.state.feature_renamedocument = false
                console.log("feature_renamedocument", this.state.feature_renamedocument);
              }
            } else {
              this.state.feature_renamedocument = false
              console.log("feature_renamedocument", this.state.feature_renamedocument);
            }

            // 
          }else{
            this.state.feature_inputmore = false
            this.state.feature_renamedocument = false
            // VueCookies.set("feature_onlyoffice",false)
            this.state.feature_onlyoffice = false
            console.log("feature_onlyoffice", this.state.feature_onlyoffice);
            this.state.feature_logo = false
            this.state.logo_default = ""
            let favicon = document.querySelector('link[rel="icon"]')
            favicon.setAttribute('type', `image/png`)
            favicon.setAttribute('href', "")
          }

          const pobj = JSON.stringify(data);
          //sessionStorage.removeItem(pobj);
          sessionStorage.setItem("biz_active", pobj);
          VueCookies.set("biz_active", pobj, "1h");
          console.log(
            "สิทธิ์outbox",
            result.data.result.business_info.status_permission_outbox
          );
          console.log(
            "สิทธิ์inbox",
            result.data.result.business_info.status_permission_inbox
          );
          console.log(result);
          let datas = result.data.result;
          datas["tax_id"] = data["taxid"];
          // console.log("datas",datas);
          commit("authorize_business_granted", datas);
          // await dispatch("cctv_accessChecker", datas.user_info.user_id);
          return new Promise((resolve, reject) => {
            resolve({ status: "Business Access Granted", msg: "OK", code: "" });
          });
        } else {
          commit("authorize_business_denied");
          return new Promise((resolve, reject) => {
            resolve({
              status: "Business Access Denied",
              msg: "Token expired, please login again",
              // msg: this.$t('loginbusiness.error'),
              // msg: result.data.errorCode + ": " + result.data.errorMessage,
              code: result.data.errorCode
            });
          });

        }
      } catch (ex) {
        commit("authorize_business_denied");
        return new Promise((resolve, reject) => {
          reject(ex);
        });
      }
    },

    // change logo default 
    change_default_logo ({commit, dispatch}, data) {
      commit('change_logo_default', data)
      return new Promise((resolve, reject) => {
        resolve({ status: "OK", msg: "OK", code: "" });
      });
    },

    // login business admin
    async authorize_login_admin_business({ commit }, data) {
      commit("authorize_login_admin_business");
      let auth = await gbfGenerate.generateToken();
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/check_admin_business",
        data,{headers: { Authorization: auth.code },}
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            // const pobj = JSON.stringify(data);
            //sessionStorage.removeItem(pobj);
            // sessionStorage.setItem("biz_active", pobj);
            console.log(result.data);
            //let datas = result.data.result;
            //datas["tax_id"] = data["taxid"];
            if (result.data.admin === "True") {
              commit("authorize_admin_business_granted");
              resolve({
                status: "Login Admin Business Access Granted",
                msg: "OK",
                code: ""
              });
            } else {
              console.log("not admin");
              commit("authorize_admin_business_granted");
              resolve({
                status: "Login Not Admin Business",
                msg: "This account is not an admin.",
                code: ""
              });
            }
          } else {
            commit("authorize_admin_business_denied");
            resolve({
              status: "Login Admin Business Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage,
              code: result.data.errorCode
            });
          }
        } catch (ex) {
          commit("authorize_admin_business_denied");
          reject(ex);
        }
      });
    },

    // login accesstoken
    async authorize_login_accesstoken({ commit, dispatch }, data) {
      commit("authorize_login");
      let payload = {
        accesstoken: data.accesstoken
      }
      let auth = await gbfGenerate.generateToken();
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_SSO + "/api/getprofile",
        payload,{headers: { Authorization: auth.code },}
      );
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            let encodetoken = window.btoa(result.data.result["accesstoken"])
            let user_id_key = (Math.random() + 1).toString(36).substring(7);
            VueCookies.set("token", encodetoken);
            VueCookies.set('token_auth', true, 60 * 60 * 24);
            VueCookies.set("user_key", user_id_key);
            VueCookies.set("accountid", CryptoJS.AES.encrypt(window.btoa(result.data.result.accountid), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("thai_email", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("user_id", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            VueCookies.set("username", CryptoJS.AES.encrypt(window.btoa(result.data.result.user_info["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
            dispatch("authorize_granted", result.data.result);
            // if (result.data.result.login_default === "") {
            //   dispatch("authorize_checking");
            // }
            resolve({ status: "Access Granted", msg: "OK", code: "" });
          } else {
            console.log("====,,,,");
            dispatch("authorize_denied");
            resolve({
              status: "Access Denied",
              msg: result.data.errorCode + ": " + result.data.errorMessage,
              code: result.data.errorCode
            });
          }
        } catch (ex) {
          dispatch("authorize_denied");
          reject(ex);
        }
      });
    },

    //สลับ Account ไปใช้ Business เมื่อ Login ของ One Email
    switchaccount_businessonemail({ commit }, data) {
      commit("switchaccount_businessonemail");
      return new Promise((resolve, reject) => {
        commit("switchaccount_businessonemail_granted", data);
        resolve({ status: "Business Access Granted", msg: "OK", code: "" });
      });
    },

    authorize_business_grant({ commit }, data) {
      commit("authorize_business_grant", data);
      return new Promise((resolve, reject) => {
        resolve("User Authorize Business Access Grant");
      });
    },

    department_access_id({ commit }, data) {
      commit("department_access_id", data);
    },

    show_loading({ commit }, data) {
      commit("show_loading", data);
    },

    check_color({ commit }, data) {
      commit("check_color", setcolors.setColor(data));
    },

    check_feature({ commit }, data) {
      commit("check_feature", data);
    },

    set_loading({ commit }, data) {
      commit("set_loading", data);
      // console.log("setloading-----1--------");
    },

    //update permission_inbox // permission_outbox (เพิ่มเติม)
    async updatepermissioninoutbox_business_manage({commit}, updatepermission) {
      commit('updatepermissioninoutbox_business_manage', updatepermission)
    },

    //triggle login_datetime === เวลาที่กำหนด / timeout
    triggle_otp_botonebox_manage ({commit}, status) {
      commit('triggle_otp_botonebox', status)
    },

    // async cctv_accessChecker({ commit, dispatch }, _data) {
    //   commit("cctv_accessChecker");
    //   let result = { status: "ER", msg: "" };
    //   try {
    //     let payload = {
    //       user_id: _data
    //     };
    //     let auth = await gbfGenerate.generateToken();
    //     let response = await axios.post(
    //       process.env.VUE_APP_SERVICE_AUTHORIZE_BUSINESS + "/api/member/cctv",
    //       payload,
    //       { headers: { Authorization: auth.code } }
    //     );

    //     if (response.data.status === "OK" && response.data.result.cctv_status === 'True') {
    //       dispatch("cctv_accessGrant", response.data.result);
    //       result = { status: "OK", msg: "" };
    //     } else {
    //       dispatch("cctv_accessDenied");
    //       result = { status: "ER", msg: response.data.errorMessage };
    //     }
    //     return new Promise(resolve => {
    //       resolve(result);
    //     });
    //   } catch (err) {
    //     console.log(err);
    //     result = { status: "ER", msg: String(err) };
    //     dispatch("cctv_accessDenied");
    //     return new Promise(resolve => {
    //       resolve(result);
    //     });
    //   }
    // },

    cctv_accessGrant({ commit }, _data) {
      commit("cctv_accessGrant", _data);
    },

    cctv_accessDenied({ commit }) {
      commit("cctv_accessDenied");
    }
  },
  getters: {
    dataOneAccountId: state => {
      return state.one_account_id;
    },
    datafeature: state => {
      return state.feature_status;
    },
    dataLoginDefault: state => {
      return state.login_default;
    },
    dataStorageUsage: state => {
      return state.storage_usage;
    },
    dataRemainStorage: state => {
      return state.remain_storage;
    },
    dataStorageMax: state => {
      return state.storage_max;
    },
    dataStorageMaxpersonalinbiz: state => {
      return state.storage_max_personal_biz;
    },
    dataAccountId: state => {
      return state.accountid;
    },
    dataDepartmentAccessId: state => {
      return state.department_access_id;
    },
    dataUsername: state => {
      return state.citizen_profile["user_id"];
    },
    dataAuthorize: state => {
      return state.authorize;
    },
    dataAccesstoken: state => {
      return state.access_token;
    },
    dataCitizenProfile: state => {
      return state.citizen_profile;
    },
    dataAccountActive: state => {
      return state.account_active;
    },
    dataBusinessProfile: state => {
      return state.business_profile;
    },
    dataTypeusercurrent: state => (id, lang) => {
      //console.log("id",id);
      let typeuser;
      if (lang === "en") {
        if (id === "Citizen") {
          typeuser = "Personal";
        } else {
          if (state.service === "OneMail") {
            let findbusiness = state.business_profile.find(
              bu => bu.taxid === state.account_active["taxid"]
            );
            //typeuser = findbusiness["first_name_eng"] || ""
            //if(findbusiness.hasOwnProperty('first_name_eng') === true){
              typeuser = findbusiness["first_name_eng"] || ""
              // }else{
              //   typeuser = ""
              // }
          } else {
            // console.log("asdfghjkl;",state.business_profile);
            let findbusiness;
            if(state.account_active["business_info"]["id"] === ""){
               findbusiness = state.business_profile.find(
                bu => bu.id_card_num === state.account_active["taxid"]
              );
            }else{
               findbusiness = state.business_profile.find(
                bu => bu.id === state.account_active["business_info"]["id"]
              );
            }
            

           // if(findbusiness.hasOwnProperty('first_name_eng') === true){
            typeuser = findbusiness["first_name_eng"] || ""
           // }else{
            //   typeuser = ""
            // }
          }
        }
      } else {
        if (id === "Citizen") {
          typeuser = "ผู้ใช้งานทั่วไป";
        } else {
          // console.log(state.service)
          if (state.service === "OneMail") {
            let findbusiness = state.business_profile.find(
              bu => bu.taxid === state.account_active["taxid"]
            );
            typeuser = findbusiness["first_name_th"] || ""
          } else {
            let findbusiness;
            if(state.account_active["business_info"]["id"] === ""){
              findbusiness = state.business_profile.find(
                bu => bu.id_card_num === state.account_active["taxid"]  && bu.branch_no === state.account_active["business_info"]["branch_no"]);
            }else{
              //console.log("เข้าelse");
               findbusiness = state.business_profile.find(
                bu => bu.id === state.account_active["business_info"]["id"] && bu.branch_no === state.account_active["business_info"]["branch_no"]);
            }
            // let findbusiness = state.business_profile.find(
            //   bu => bu.id === state.account_active["business_info"]["id"]
            // );
            typeuser = findbusiness["first_name_th"] || ""
          }
        }
      }
      return typeuser;
    },
    dataAccountCCTV: state => {
      return state.account_cctv;
    },
    dataCheckPackage: state => {
      return {
        type: state.account_active.type,
        package: true  //state.account_active.check_package === 'Y'
      }
      // if (state.account_active.type === 'Business') {
      //   console.log('state.account_active',state.account_active);
      //   return {
      //     type: 'Business',
      //     package: state.account_active.check_package === 'Y'
      //   }
      //   // const business = state.account_active.business_info.id === "" ? state.business_profile.filter(biz => biz.id_card_num === state.account_active.taxid) : state.business_profile.filter(biz => biz.id === state.account_active.business_info.id)
      //   // if (business.length > 0) {
      //   //   return {
      //   //     type: 'Business',
      //   //     package: business[0].check_package === 'Y'
      //   //   }
      //   // } else {
      //   //   return {
      //   //     type: 'Business',
      //   //     package: false
      //   //   }
      //   // }
      // } else {
      //   return {
      //     type: 'Citizen',
      //     package: state.account_active.check_package === 'Y'
      //   }
      // }
    }
  },
  modules: {},
  plugins: [
    // createPersistedState({
    //   key: "oneboxPersistedState",
    //   storage: {
    //     getItem: key => ls.get(key),
    //     setItem: (key, value) => ls.set(key, value),
    //     removeItem: key => ls.remove(key)
    //   }
    // })
  ]
});

